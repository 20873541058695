<template>
  <el-container class="h-full">
    <el-container>
      <el-main>
        <div style="height: 50px; ">
          <page-header
            style="padding-left: 0.7rem; width: calc(100% - 335px)"
            :title="__('Move Account')"
          />
        </div>
        <move-account-form />
      </el-main>
    </el-container>
    <el-aside class="form-aside relative" :width="`${asideWidth}px`">
      <div class="aside-content">
        <div style="font-size: 14px;padding: 30px 15px;color: #A0A8B5">
          {{ asideText }}
        </div>
      </div>
      <slot name="form"></slot>
    </el-aside>
  </el-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import PageHeader from "@/components/PageHeader";
import MoveAccountForm from "./pages/MoveAccountForm";

export default {
  name: "MoveAccountIndex",
  components: {
    PageHeader,
    MoveAccountForm
  },
  props: {
    formAction: {
      default: ""
    },
    asideText: {
      default: ""
    },
    asideButtonText: {
      default: __("Create")
    },
    asideWidth: {
      type: Number,
      required: false,
      default: 274
    }
  },
  data() {
    return {
      activeName: "connect"
    };
  },

  computed: {
    ...mapState("systemsettings", {
      contents: state => state.settings
    })
  },

  methods: {
    ...mapActions("serviceproviders", {
      fetchAccountsForServiceProvider: "fetchAccountsForServiceProvider",
      fetchTrunksForServiceProvider: "fetchTrunksForServiceProvider",
      getServiceProviders: "getServiceProviders"
    })
  },
  mounted() {
    this.fetchAccountsForServiceProvider();
    this.fetchTrunksForServiceProvider();
    this.getServiceProviders();
  }
};
</script>

<style lang="scss" scoped>
.h-full {
  height: 100%;
}

.aside-content {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.form-aside {
  background: white;
  border-left: #f5f5f8 1px solid;
}
</style>
